import React from "react";
import { BsBook, BsClock, BsPerson } from "react-icons/bs";
import { Recipe } from "../recipe.interface";
import { useRouter } from "next/router";
import { translate } from "react-i18nify";
import Imgix from "react-imgix";
import { getImgUrl } from "@goodfood/utils";
import classNames from 'classnames';
import lazySizes from 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';

interface Props {
  recipe: Recipe;
  includeFooter?: boolean;
  isLink?: boolean;
}
export const RecipeCard = ({
  recipe: {
    title,
    duration,
    numberOfServings,
    recipeIngredients,
    image,
    subTitle,
    id,
  },
  includeFooter = true,
  isLink= true
}: Props) => {
  const router = useRouter();
  return (
    <div>
      <div
        className={classNames("card mb-4",{"cursor-pointer": isLink})}
        onClick={() =>
          isLink && router.push({
            pathname: `/recipes/${id}`,
          })
        }
      >
        <Imgix 
        attributeConfig={{
                    src: "data-src",
                    srcSet: "data-srcset",
                    sizes: "data-sizes",
                  }} 
                  src={getImgUrl(`recipes/${image}`)} imgixParams={{ fit: "crop" }} width={300} height={232} sizes="100vw" className="img-fluid card-img-top lazyload" />
        <div className="card-body">
          <h5 className="text-large overflow-hidden text-nowrap text-overflow-ellipsis m-0">{title}</h5>
          <p className="card-text overflow-hidden text-nowrap text-overflow-ellipsis text-small">
            {subTitle}
          </p>
          {
            includeFooter && (
              <>
              <hr />
              <div className="row">
                <div className="col-4 text-center d-flex flex-column">
                  <span>
                    <BsClock />
                    <span className="ml-2 text-small vertical-align-middle text-primary">
                      {duration}
                    </span>
                  </span>
                  <span className="text-xsmall">
                    {translate("recipeCard.minutes")}
                  </span>
                </div>
                <div className="col-4 text-center d-flex flex-column">
                  <span>
                    <BsBook />
                    <span className="ml-2 text-small vertical-align-middle text-primary">
                      {recipeIngredients ? recipeIngredients.length : ""}
                    </span>
                  </span>
                  <span className="text-xsmall">
                    {translate("recipeCard.ingredients")}
                  </span>
                </div>
                <div className="col-4 text-center d-flex flex-column">
                  <span>
                    <BsPerson />
                    <span className="ml-2 text-small vertical-align-middle text-primary">
                      {numberOfServings}
                    </span>
                  </span>
                  <span className="text-xsmall">
                    {translate("recipeCard.servings")}
                  </span>
                </div>
              </div>
              </>
            )
          }

        </div>
      </div>

      <style jsx>{`
        .btn {
          letter-spacing: 0.5px;
        }
      `}</style>
    </div>
  );
};
