import { createAction } from "@goodfood/utils";
import { Recipe } from "../recipe.interface";


export type GetRecipesActions = GetRecipesAction | GetRecipesSuccessAction | GetRecipesFailureAction;

export const GET_RECIPES_TYPE = '[GOOD FOOD SHOP] Get recipes';
export type GetRecipesAction = ReturnType<typeof getRecipes>;
export const getRecipes = () => createAction(GET_RECIPES_TYPE);

export const GET_RECIPES_SUCCESS_TYPE = '[GOOD FOOD SHOP] Get recipes success';
export type GetRecipesSuccessAction = ReturnType<typeof getRecipesSuccess>;
export const getRecipesSuccess = (recipes: Recipe[]) => createAction(GET_RECIPES_SUCCESS_TYPE, recipes);

export const GET_RECIPES_FAILURE_TYPE = '[GOOD FOOD SHOP] Get recipes failure';
export type GetRecipesFailureAction = ReturnType<typeof getRecipesFailure>;
export const getRecipesFailure = (error: any) => createAction(GET_RECIPES_FAILURE_TYPE, error);
