import * as GetRecipesActions from './actions';
import { takeLatest, put, call } from 'redux-saga/effects';
import { recipesService } from '../recipes.service';
import { Recipe } from '../recipe.interface';

export function* getRecipesSaga({ payload }: GetRecipesActions.GetRecipesAction) {
    try {
        const recipes: Recipe[] = yield call(recipesService.getRecipes);
        yield put(GetRecipesActions.getRecipesSuccess(recipes));
    } catch (error) {
        yield put(GetRecipesActions.getRecipesFailure(error));
    }
}

export const GetRecipesSagas = [takeLatest(GetRecipesActions.GET_RECIPES_TYPE, getRecipesSaga)];
