import { Recipe } from "../recipe.interface";

export interface GetRecipesState {
    isLoading: boolean;
    error: any;
    recipes: Recipe[]
}

export const getRecipesInitialState: GetRecipesState = {
    isLoading: false,
    error: null,
    recipes:[]
};
