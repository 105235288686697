import { createAction } from "@goodfood/utils";
import { Recipe } from "../recipe.interface";

export type GetRecipeActions =
  | GetRecipeAction
  | GetRecipeSuccessAction
  | GetRecipeFailureAction;

export const GET_RECIPE_TYPE = "[GOOD FOOD SHOP] Get Recipe";
export type GetRecipeAction = ReturnType<typeof getRecipe>;
export const getRecipe = (recipeId: string) =>
  createAction(GET_RECIPE_TYPE, { recipeId });

export const GET_RECIPE_SUCCESS_TYPE = "[GOOD FOOD SHOP] Get Recipe success";
export type GetRecipeSuccessAction = ReturnType<typeof getRecipeSuccess>;
export const getRecipeSuccess = (recipe: Recipe) =>
  createAction(GET_RECIPE_SUCCESS_TYPE, recipe);

export const GET_RECIPE_FAILURE_TYPE = "[GOOD FOOD SHOP] Get Recipe failure";
export type GetRecipeFailureAction = ReturnType<typeof getRecipeFailure>;
export const getRecipeFailure = (error: any) =>
  createAction(GET_RECIPE_FAILURE_TYPE, error);
