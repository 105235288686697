import * as Actions from "./actions";
import { GetRecipeState, getRecipeInitialState } from "./state";

export const getRecipeReducer = (
  state: GetRecipeState = getRecipeInitialState,
  action: Actions.GetRecipeActions
): GetRecipeState => {
  switch (action.type) {
    case Actions.GET_RECIPE_TYPE:
      return { ...state, isLoading: true };
    case Actions.GET_RECIPE_SUCCESS_TYPE:
      return { ...state, isLoading: false, recipe: action.payload };
    case Actions.GET_RECIPE_FAILURE_TYPE:
      return { ...state, isLoading: false, error: action.payload };
    default:
      return state;
  }
};
