import { Recipe } from "./recipe.interface";
import { fetchClient } from "@goodfood/lib";
import { getEndpoint } from "@goodfood/utils";

async function getRecipes(): Promise<Recipe[]> {
  return (await fetchClient.getData<Recipe[]>(getEndpoint(`/recipe`)))!;
}
async function getRecipe(recipeId: string): Promise<Recipe> {
  return (await fetchClient.getData<Recipe>(
    getEndpoint(`/recipe/${recipeId}`)
  ))!;
}

export const recipesService = {
  getRecipes,
  getRecipe,
};
