import { Recipe } from "../recipe.interface";
export interface GetRecipeState {
  isLoading: boolean;
  error: any;
  recipe: Recipe;
}

export const getRecipeInitialState: GetRecipeState = {
  isLoading: false,
  error: null,
  recipe: null,
};
