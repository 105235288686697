import React from "react";
import {  BsPlusCircle, BsDashCircle } from "react-icons/bs";
import { Recipe } from "../recipe.interface";
import { useRouter } from "next/router";
import { translate } from "react-i18nify";
import Imgix from "react-imgix";
import { getImgUrl } from "@goodfood/utils";
import Link from "next/link";
import { BoxItem } from "@mealhaus/components/SelectRecipes";
import lazySizes from 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';

interface Props {
  recipe: {id: string; quantity: number; recipe: Recipe};
}
export const SelectedRecipeCard = ({
  recipe: {
    id,
    quantity,
    recipe:{ image, title, subTitle, duration}
  }
}: Props) => {

  return (
    <div>
      
      <div
        className="card mb-4 cursor-pointer text-decoration-none text-reset"
      >
        <Imgix
        attributeConfig={{
          src: "data-src",
          srcSet: "data-srcset",
          sizes: "data-sizes",
        }}
         src={getImgUrl(`recipes/${image}`)} imgixParams={{ fit: "crop" }} width={300} height={232} sizes="100vw" className="img-fluid card-img-top lazyload" />
        <div className="card-body">
          <div className="text-large overflow-hidden text-nowrap text-overflow-ellipsis m-0 font-weight-500">{title}</div>
          <p className="card-text overflow-hidden text-nowrap text-overflow-ellipsis text-small m-0">
            {subTitle}
          </p>
          <p className="text-small font-weight-500">
          {duration} {translate("recipeCard.minutes")}
          </p>
          <hr />
          <div className="text-center d-flex justify-content-center align-items-center">
           <span className="text-small">{`${quantity} in box`}</span>
          </div>
          
        </div>
      </div>
      

      <style jsx>{`
        .btn {
          letter-spacing: 0.5px;
        }
      `}</style>
    </div>
  );
};
