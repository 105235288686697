import * as Actions from './actions';
import { GetRecipesState, getRecipesInitialState } from './state';

export const getRecipesReducer = (
    state: GetRecipesState = getRecipesInitialState,
    action: Actions.GetRecipesActions
): GetRecipesState => {
    switch (action.type) {
        case Actions.GET_RECIPES_TYPE:
            return { ...state, isLoading: true };
        case Actions.GET_RECIPES_SUCCESS_TYPE:
            return { ...state, isLoading: false, recipes: action.payload };
        case Actions.GET_RECIPES_FAILURE_TYPE:
            return { ...state, isLoading: false, error: action.payload };
        default:
            return state;
    }
};
