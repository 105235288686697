import * as GetRecipeActions from "./actions";
import { takeLatest, put, call } from "redux-saga/effects";
import { Recipe } from "../recipe.interface";
import { recipesService } from "../recipes.service";

export function* getRecipeSaga({ payload }: GetRecipeActions.GetRecipeAction) {
  try {
    const recipe: Recipe = yield call(
      recipesService.getRecipe,
      payload.recipeId
    );
    yield put(GetRecipeActions.getRecipeSuccess(recipe));
  } catch (error) {
    yield put(GetRecipeActions.getRecipeFailure(error));
  }
}

export const GetRecipeSagas = [
  takeLatest(GetRecipeActions.GET_RECIPE_TYPE, getRecipeSaga),
];
